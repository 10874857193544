




























































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { CropVariety } from "@/store/models/cropVariety";
import { CropVarietyStore, CropStore, DSADStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import UploadMultipleImage from "@/views/Admin/UploadMultipleImage.vue";
import CropVarietyValueList from "@/views/Admin/CropVarietyValueList.vue";
import { DSADGroup } from "@/store/models/dsad";
import { Validator, Rule } from "vee-validate";

Validator.extend("time", {
  getMessage: (field: any) => {
    // let previousField = "T" + Number(Number(String(field).split("T")[1]) - 1);
    let thisField = field;

    return thisField + " must be greater than previous field";
    // return thisField + " must be greater than than " + previousField;
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // If added new
      if (args[0] && args[1]) {
        let previousYear = String(Number(args[1]) - 1);
        if (args[1] == "2") {
          previousYear = String(Number(args[1]) - 2);
        }
        let tayear = args[0]["t" + previousYear + "year"];
        let tamonth = args[0]["t" + previousYear + "month"];
        let taday = args[0]["t" + previousYear + "day"];

        let tbyear = args[0]["t" + args[1] + "year"];
        let tbmonth = args[0]["t" + args[1] + "month"];
        let tbday = args[0]["t" + args[1] + "day"];

        if (tbyear < tayear) {
          return false;
        } else if (tbyear == tayear) {
          if (tbmonth < tamonth) {
            return false;
          } else if (tbmonth == tamonth) {
            if (tbday <= taday) {
              return false;
            }
          }
        }
        return true;
      }
      return false;
    }
    return false;
  }
} as Rule);

@Component({
  components: {
    CropVarietyValueList,
    DynamicField,
    UploadMultipleImage
  },
  metaInfo: {
    title: "Demand Supply Schedule",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "List of demand supply group",
        vmid: "demandList"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class SCMList extends Vue {
  search: string = "";
  dsadId: number = 0;
  loading: boolean = false;
  saving: boolean = false;
  year: any = [{ label: "This Year", value: 0 }, { label: "Next Year", value: 1 }];

  group: any = {
    id: 0,
    varieties: [],
    variety: []
  } as any;

  get month() {
    let month = [
      { label: "Baishakh", value: 1 },
      { label: "Jeth", value: 2 },
      { label: "Asar", value: 3 },
      { label: "Shrawan", value: 4 },
      { label: "Bhadra", value: 5 },
      { label: "Asoj", value: 6 },
      { label: "Kartik", value: 7 },
      { label: "Manshir", value: 8 },
      { label: "Paush", value: 9 },
      { label: "Magh", value: 10 },
      { label: "Falgun", value: 11 },
      { label: "Chaitra", value: 12 }
    ];
    // if (this.$i18n.locale == "ne") {
    //   month = [
    //     { label: "वैशाख", value: 1 },
    //     { label: "जेठ", value: 2 },
    //     { label: "असार", value: 3 },
    //     { label: "साउन", value: 4 },
    //     { label: "भदौ", value: 5 },
    //     { label: "असोज", value: 6 },
    //     { label: "कार्तिक", value: 7 },
    //     { label: "मंसिर", value: 8 },
    //     { label: "पुष", value: 9 },
    //     { label: "माघ", value: 10 },
    //     { label: "फागुन", value: 11 },
    //     { label: "चैत", value: 12 }
    //   ];
    // }
    return month;
  }

  private get crops() {
    return CropStore.CropNameList;
  }
  private get selectedVarieties() {
    let varieties = this.group.variety;
    let varDict: any = {};
    if (varieties != undefined) {
      varieties.sort((a: any, b: any) =>
        a.varname > b.varname ? 1 : b.varname > a.varname ? -1 : 0
      );
      for (let variety of varieties) {
        if (variety.selected == true) {
          varDict[variety.varietyname] = 1;
        }
      }
    }
    return varDict;
  }

  allVarieties: CropVariety[] = [];

  changeCrop() {
    this.allVarieties = JSON.parse(
      JSON.stringify(
        CropVarietyStore.CropVarieties.filter(x => x.croptypeid == this.group.croptypeid)
      )
    );
    this.allVarieties = this.allVarieties
      .filter(x => x.croptypeid == this.group.croptypeid)
      .map(x => {
        if (this.selectedVarieties[x.name]) {
          x.selected = true;
        } else {
          x.selected = false;
        }
        return x;
      });
    this.allVarieties.sort((a: any, b: any) => {
      let val: number = 0;
      if (this.selectedVarieties[a.name]) {
        if (this.selectedVarieties[b.name]) {
          val = a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        } else {
          val = -1;
        }
      } else {
        val = 1;
      }
      return val;
    });
  }
  private get cropvarietyforadmin() {
    return CropVarietyStore.CropVarietyForAdmin;
  }

  rerender = 0;

  rerenderCheckbox(event: boolean, row: any) {
    this.rerender = new Date().getTime();
    const allVarieties = JSON.parse(JSON.stringify(this.allVarieties)) as CropVariety[];
    const index = allVarieties.findIndex(x => x.id == row.id);
    allVarieties[index].selected = event;
    console.log(event, row.selected, allVarieties[index].selected);
    this.allVarieties = allVarieties;
  }

  async saveGroup() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.group.varieties = [];
      let checkselected = this.allVarieties
        .filter(x => x.croptypeid == this.group.croptypeid)
        .filter(x => x.selected == true);
      for (let sel of checkselected) {
        this.group.varieties.push(sel.id);
      }
      this.saving = true;
      let error = false;
      this.group.t1day = this.group.t2day;
      this.group.t1month = this.group.t2month;
      this.group.t1year = this.group.t2year;
      await DSADStore.saveDSADGroup(this.group).catch(err => {
        error = true;
      });
      this.saving = false;
      if (!error) {
        this.$notify.success("Saved Successfully");
        if (DSADStore.DSADGroup != undefined && DSADStore.DSADGroup != null && this.group.id == 0) {
          this.group = await DSADStore.DSADGroup;
          if (this.$route.name) {
            this.$router
              .replace({
                name: this.$route.name,
                query: { dsadId: this.group.id }
              })
              .catch(error => {});
          }
        }
      }
    }
  }
  private async created() {
    this.loading = true;
    CropStore.getAllCropName();

    await CropVarietyStore.getAllCropVariety();

    if (this.$route.query.dsadId) {
      this.dsadId = Number(this.$route.query.dsadId);
      await DSADStore.getDSADGroupById(this.dsadId);
      this.group = DSADStore.DSADGroup;
      this.changeCrop();
    }
    this.loading = false;
  }
}
